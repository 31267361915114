<template>
  <div class="update-pass">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user' }">会员中心</el-breadcrumb-item>
      <el-breadcrumb-item>修改密码</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <h3>修改密码</h3>
      <el-form label-width="120px">
        <el-form-item label="输入密码">
          <el-input placeholder="输入密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input placeholder="确认密码"></el-input>
        </el-form-item>
        <div style="display: flex;justify-content: space-between">
          <el-button>返回</el-button>
          <el-button>提交</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'updatePass'
}
</script>

<style scoped lang="scss">
@import "src/scss/index";
.update-pass {
  @include body;
}
</style>
